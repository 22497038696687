import React from 'react'
import styled from 'styled-components'
import LegalMenu from '../components/legalMenu'
import Meta from '../components/layout/meta'
import { Layout } from '../components/layout'
import { LegalContent, StickyLegalMenu, TextContainer } from '../utils'

const CookieLink = styled.a`
    color: ${({ theme }) => theme.color.tellowBlue};
    font-weight: bold;
`

const CookiesPage = () => (
    <Layout>
        <Meta title="Tellow - Cookies" description="Tellow's cookie informatie." path="/cookies" />
        <LegalContent>
            <StickyLegalMenu>
                <LegalMenu className="sticky" />
            </StickyLegalMenu>
            <TextContainer>
                <h1>Cookies</h1>
                <div className="col-lg-8 padding-top padding-bottom">
                    <p>
                        <strong>Wat zijn cookies?</strong>
                    </p>
                    <p>
                        Cookies zijn kleine, eenvoudige tekstbestanden die wij op je computer, tablet of mobiele telefoon plaatsen als je onze website bezoekt. In deze cookies
                        wordt informatie over jouw websitebezoek bijgehouden. Bij jouw volgende bezoek met hetzelfde apparaat wordt de informatie in dit tekstbestand geraadpleegd,
                        zodat wij je kunnen herkennen.
                    </p>
                    <p>
                        <strong>Veilig en vertrouwelijk</strong>
                    </p>
                    <p>
                        De informatie die is opgeslagen in de cookies wordt alleen gebruikt door deze website. Uiteraard verstrekken wij deze gegevens niet zonder jouw toestemming
                        aan derden. Wij volgen je niet als je naar een andere website gaat.
                    </p>
                    <h2>Voor welke doelen gebruiken wij cookies?</h2>
                    <p>
                        <strong>Functionele cookies</strong>
                    </p>
                    <p>
                        Sommige cookies zijn nodig om de primaire functies van de website te kunnen gebruiken. Bijvoorbeeld om taalinstellingen te onthouden, maar ook om te
                        voorkomen dat de website overbelast raakt. Wij hoeven je geen toestemming te vragen om deze cookies te plaatsen of te lezen. Wij plaatsen deze cookies
                        altijd.
                    </p>
                    <p>
                        <strong>Niet-functionele cookies</strong>
                    </p>
                    <p>
                        Naast functionele cookies gebruiken we ook cookies die de website verbeteren en optimaliseren. Bepaalde delen van de website zijn afhankelijk van deze
                        cookies en zullen dus niet werken zonder deze te plaatsen.
                    </p>
                    <p>
                        Wij gebruiken ook cookies om te analyseren hoe vaak de website bezocht wordt en welke delen van de website meer bezocht worden. We gebruiken deze informatie
                        om de website te verbeteren. Deze informatie zal niet gebruikt worden om persoonlijke profielen te maken.
                    </p>
                    <p>
                        Deze cookies gebruiken wij voor het tonen van passende advertenties, ook op externe websites en het verbeteren van de communicatie op basis van je
                        klantprofiel. Met deze cookies volgen wij en derden je internetgedrag en worden er persoonsgegevens verzameld; bijvoorbeeld je IP-adres en bezochte
                        webpagina’s. Derden (zoals Google) kunnen ook een analyse uitvoeren of een video tonen en hierbij cookies plaatsen. Voor al deze cookies vragen wij je
                        toestemming. Als je ervoor kiest deze cookies niet te accepteren, dan is het nog steeds mogelijk gebruik te maken van Tellow.nl.
                    </p>
                    <p>
                        <strong>Instellingen cookies veranderen</strong>
                    </p>
                    <p>
                        Je kunt voorkomen dat websites cookies plaatsen op jouw computer, tablet of mobiele telefoon. Dit doet je door jouw browserinstellingen aan te passen. Je
                        kunt hier ook andere instellingen voor cookies aanpassen, bijvoorbeeld een waarschuwing ontvangen als er cookies worden geplaatst. Je moet deze instellingen
                        voor iedere browser die je gebruikt apart aanpassen. Als je cookies van Tellow.nl weigert, kan het zijn dat onze website niet meer goed werkt. Of dat je bij
                        ieder bezoek de vraagt krijgt of je cookies accepteert.
                    </p>
                    <h2>
                        <b>Soorten cookies</b>
                    </h2>
                    <p>Vind hieronder een overzicht van de soorten cookies.</p>
                    <table className="gegevens" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td className="top" valign="top">
                                    <strong>Functionele cookies</strong>
                                </td>
                                <td className="top" valign="top">
                                    <strong>Kenmerkend</strong>
                                </td>
                                <td className="top" valign="top">
                                    <strong>Duur</strong>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">_pk_ses</td>
                                <td valign="top">Identificatie voor het onderscheiden van verschillende browsersessies, geanonimiseerd.</td>
                                <td valign="top">Bestaat 30 minuten.</td>
                            </tr>
                            <tr>
                                <td valign="top">cookieconsent_status, consent</td>
                                <td valign="top">Status van cookievoorkeur op deze website</td>
                                <td valign="top">Bestaat tot 4 jaar</td>
                            </tr>
                            <tr>
                                <td valign="top">_pk_id</td>
                                <td valign="top">Onderscheiden van individuele bezoekers, geanonimiseerd.</td>
                                <td valign="top">Bestaat 1 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">CONSENT</td>
                                <td valign="top">Google concent cookie.</td>
                                <td valign="top">Bestaat 20 jaar.</td>
                            </tr>
                            <tr>
                                <td className="top" valign="top">
                                    <strong>Niet-functionele cookies</strong>
                                </td>
                                <td className="top" valign="top">
                                    <strong>Kenmerkend</strong>
                                </td>
                                <td className="top" valign="top">
                                    <strong>Duur</strong>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">_clck, _clsk</td>
                                <td valign="top">Sessiecookie voor Microsoft Clarity, Gegenereerde data is geanonimiseerd.</td>
                                <td valign="top">Bestaat maar één sessie.</td>
                            </tr>
                            <tr>
                                <td valign="top">NID</td>
                                <td valign="top">Google preferences zoals: taal, aantal zoekresultaten, safesearch.</td>
                                <td valign="top">Bestaat 6 maanden.</td>
                            </tr>
                            <tr>
                                <td valign="top">APISID, SID, 1P_JAR</td>
                                <td valign="top">Google display network cookies, voor het meten en targetten van advertenties.</td>
                                <td valign="top">Bestaat tot 2 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">_gat</td>
                                <td valign="top">Google analytics cookie om het aantal requests te limiteren.</td>
                                <td valign="top">Bestaat 10 minuten.</td>
                            </tr>
                            <tr>
                                <td valign="top">_gid</td>
                                <td valign="top">
                                    Google analytics identificatie.
                                    <br />
                                    Als je het script van Analytics liever helemaal wilt blokkeren dan{' '}
                                    <CookieLink href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                                        kun je hier een opt-out plug-in voor Google Analytics downloaden
                                    </CookieLink>
                                    .
                                </td>
                                <td valign="top">Bestaat 24 uur.</td>
                            </tr>
                            <tr>
                                <td valign="top">_ga</td>
                                <td valign="top">Google analytics lange termijn identificatie.</td>
                                <td valign="top">Bestaat 2 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">_gaexp</td>
                                <td valign="top">Google experiment cookie, voor A/B tests.</td>
                                <td valign="top">Bestaat 1 maand.</td>
                            </tr>
                            <tr>
                                <td valign="top">IDE</td>
                                <td valign="top">Doubleclick cookies voor het meten en targetten van advertenties.</td>
                                <td valign="top">Bestaat 1 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">YSC, PREF, VISITOR_INFO1_LIVE</td>
                                <td valign="top">Youtube cookies, voor functionaliteit en identificatie in de Youtube player.</td>
                                <td valign="top">Bestaat tot 2 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">intercom-id-xxxxx intercom-lou-xxxxx intercom-session-xxxxx</td>
                                <td valign="top">Intercom cookies, bewaren identiteit en eerdere chats.</td>
                                <td valign="top">Bestaat tot 20 jaar.</td>
                            </tr>
                            <tr>
                                <td valign="top">Fr, act, c_user, datr, pl, presence, sb, wd, xs</td>
                                <td valign="top">Deze worden gebruikt om het advertentieaanbod op Facebook te optimaliseren.</td>
                                <td valign="top">Bestaat tot 2 maanden.</td>
                            </tr>
                            <tr>
                                <td valign="top">amplitude_id</td>
                                <td valign="top">Deze worden gebruikt om gedrag van onze software te achterhalen voor productverbeteringen.</td>
                                <td valign="top">Bestaat tot 2 maanden.</td>
                            </tr>
                            <tr>
                                <td valign="top">sbjs_current, sbjs_current_add, sbjs_first, sbjs_first_add, sbjs_session, sbjs_udata, sbjs_promo</td>
                                <td valign="top">Marketingoptimalisatie en attributie.</td>
                                <td valign="top">Bestaat tot 1 maand.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TextContainer>
        </LegalContent>
    </Layout>
)

export default CookiesPage
